.sc-columns {
  display: flex;
  box-sizing: border-box;
  gap: var(--sc-column-spacing, var(--sc-spacing-xxxx-large));
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  // Responsiveness: Allow wrapping on mobile.
  flex-wrap: wrap !important;
  @media (min-width: 782px) {
    flex-wrap: nowrap !important;
  }

  // Ensure full vertical column stretch when alignment is not set.
  // This overrides the Layout block support's default align-items setting of `center`.
  align-items: initial !important;

  /**
	* All Columns Alignment
	*/
  &.are-vertically-aligned-top {
    align-items: flex-start;
  }

  &.are-vertically-aligned-center {
    align-items: center;
  }

  &.are-vertically-aligned-bottom {
    align-items: flex-end;
  }

  &:not(.is-not-stacked-on-mobile).is-full-height > sc-column {
    @media (max-width: 781px) {
      padding: 30px !important;
    }
  }

  &:not(.is-not-stacked-on-mobile) > sc-column {
    max-width: none;
    // Responsiveness: Show at most one columns on mobile. This must be
    // important since the Column assigns its own width as an inline style.
    @media (max-width: 781px) {
      flex-basis: 100% !important;
    }

    // At large viewports, show all columns horizontally.
    @media (min-width: 782px) {
      // Available space should be divided equally amongst columns without an
      // assigned width. This is achieved by assigning a flex basis that is
      // consistent (equal), would not cause the sum total of column widths to
      // exceed 100%, and which would cede to a column with an assigned width.
      // The `flex-grow` allows columns to maximally and equally occupy space
      // remaining after subtracting the space occupied by columns with
      // explicit widths (if any exist).
      flex-basis: 0;
      flex-grow: 1;

      // Columns with an explicitly-assigned width should maintain their
      // `flex-basis` width and not grow.
      &[style*='flex-basis'] {
        flex-grow: 0;
      }
    }
  }

  &.is-not-stacked-on-mobile {
    flex-wrap: nowrap !important;

    > sc-column {
      // Available space should be divided equally amongst columns.
      flex-basis: 0;

      flex-grow: 1;
      // Columns with an explicitly-assigned width should maintain their
      // `flex-basis` width and not grow.
      &[style*='flex-basis'] {
        flex-grow: 0;
      }
    }
  }

  &.is-full-height {
    @media (min-width: 782px) {
      min-height: 100vh !important;
    }
  }
  &.is-reversed-on-mobile {
    @media (max-width: 782px) {
      flex-direction: column-reverse;
    }
  }
}

sc-column {
  flex-grow: 1;

  // Prevent the columns from growing wider than their distributed sizes.
  min-width: 0;

  // Prevent long unbroken words from overflowing.
  word-break: break-word; // For back-compat.
  overflow-wrap: break-word; // New standard.

  /**
	* Individual Column Alignment
	*/
  &.is-vertically-aligned-top {
    align-self: flex-start;
  }

  &.is-vertically-aligned-center {
    align-self: center;
  }

  &.is-vertically-aligned-bottom {
    align-self: flex-end;
  }

  &.is-vertically-aligned-top,
  &.is-vertically-aligned-center,
  &.is-vertically-aligned-bottom {
    width: 100%;
  }

  // if block constrained
  @media (min-width: 782px) {
    &.is-layout-constrained {
      > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
        max-width: var(--sc-column-content-width) !important;
      }

      &.is-horizontally-aligned-right {
        > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
          margin-left: auto !important;
          margin-right: 0 !important;
        }
      }
      &.is-horizontally-aligned-left {
        > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
          margin-right: auto !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  @media (min-width: 782px) {
    &.is-sticky {
      position: sticky !important;
      align-self: flex-start;
      top: 0;
    }
  }
}
